// Variables

$brand-primary: #a50034;
$gold: #ae9962;
$brand-danger: #e74c3c;
$theme-primary:    #a50034;
$gray-darkest: #222;
$gray: #999;
$gray-lighter: #f7f7f7;
$placeholder-text: #bbbbbb;
