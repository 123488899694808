.Post.ImageScale {
    padding: 0 15px;
}
.Post a {
  color: inherit !important;
  text-decoration: none !important;
}
.Post-image {
  display: block;
  margin: 0;
  width: 100%;
}
.Post-meta {
  display: block;
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.Post-metaDate {
  position: relative;
  margin-top: 0.7em;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
}
.Post-metaDate::before {
  content: '';
  display: block;
  position: absolute;
  top: 0.5em;
  left: -33px;
  width: 25px;
  height: 1px;
  background-color: currentColor;
}
.Post-metaTitle {
  margin-top: 0.7em;
  min-height: 50px;
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
}
.Post-metaPreview {
  margin: 0;
  font-size: 18px;
  font-weight: 300;
}
.Post-author {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 2em;
  margin-left: 15px;
  margin-right: 15px;
}
.Post-author .Post-authorName {
  display: flex;
}
.Post-authorImage {
  margin-right: 15px;
  border-radius: 55px;
}
.Post-authorName {
  vertical-align: middle;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.ImageScale .ImageScale-image,
.ImageScale .ImageScale-container {
  transition: transform 0.5s !important;
}
.ImageScale .ImageScale-container {
  display: block;
  overflow: hidden !important;
}
.ImageScale:hover .ImageScale-container {
  transform: scale(0.9);
}
.ImageScale:hover .ImageScale-image {
  transform: scale(1.2);
}
.Post-image {
    padding-top: 80%;
    height: 0;
    background-size: cover;
    background-position: 50%;
    display: block;
    margin: 0;
    width: 100%;
}

.Post-action {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;

  span {
      vertical-align: middle;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      display: inline-block;
      padding: 4px 0;
      position: relative;
      text-decoration: none!important;
      color: $brand-primary;
      &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          height: 1px;
          background-color: currentColor;
          transition: width .5s;
          will-change: width;
          right: 0;
          width: 0;
      }
      &:hover {
          &:before {
              left: 0;
              right: auto;
              width: 100%;
          }
      }
  }
}

.Post:hover .Post-action span:before {
  left: 0;
  right: auto;
  width: 100%;
}

/*Flex items*/
.teaser-section {
  display: flex;
  flex-wrap: wrap;
}
.teaser {
  display: flex; 
  padding: 0.5em;
  width: 100%;
  margin-bottom: 1em;
}
@media all and (min-width: 40em) {
  .teaser {
    width: 50%;
  }
}
@media all and (min-width: 60em) {
  .teaser {
    width: 33.33%;
  }
}