// Global Components
body {
    overflow-x: hidden;
    @include body-font;
}

.text-muted {
    color: $gray;
}
.text-small {
    font-size: .75em;
}

.text-primary {
    color: $brand-primary;
}

p,
ol,
ul {
    font-size: 1.3125em;
    font-weight: 300;
    line-height: 1.75;
}

p.large {
    font-size: 2em;
}

a,
a.active,
a:active,
a:focus,
a:hover {
    outline: none;
}

a {
    color: $brand-primary;
}

a.active,
a:active,
a:focus,
a:hover {
    color: darken($brand-primary, 10%);
}

a.text-brand-primary {
    color: $brand-primary!important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include heading-font;
    font-weight: 700;
}

.img-centered {
    margin: 0 auto;
}

.img-responsive {
    width: 100%;
}

.magazine-title {
    width: 100%;
    max-width: 300px;
}
.txt-expanded {
    //letter-spacing: .0875em;
}
.nobr {
    white-space: nowrap;
}
.bg-light-gray {
    background-color: $gray-lighter;
}

.bg-darkest-gray {
    background-color: $gray-darkest;
}

// Restyled Primary Buttons
.btn-primary {
    @include button-variant(white, $brand-primary, $brand-primary);
    @include heading-font;
    font-weight: 600;
    border-radius: 40px;
    padding: 15px 30px;
    letter-spacing: .03em;
}

.btn-xl {
    @include button-variant(white, $brand-primary, $brand-primary);
    @include heading-font;
    font-weight: 600;
    border-radius: 40px;
    font-size: 1em;
    padding: 20px 25px;
    letter-spacing: .03em;
    white-space: inherit;

    @media (min-width: 768px) {
        font-size: 1.125em;
        padding: 20px 40px;
    }
}

.top-buffer-10 {
    margin-top: 10px;
}

.top-buffer-20 {
    margin-top: 20px;
}

.top-buffer-30 {
    margin-top: 30px;
}

.top-buffer-40 {
    margin-top: 40px;
}

.top-buffer-50 {
    margin-top: 50px;
}

.top-buffer-100 {
    margin-top: 100px;
}

.nl-small {
    font-size: 1.125em;
    font-weight: 400;
}

// Custom Navigation Bar
.navbar-default {
    background-color: $gray-darkest;
    border-color: transparent;
    @include box-shadow;

    .navbar-brand {
        color: $brand-primary;
        @include script-font;

        &.active,
        &:active,
        &:focus,
        &:hover {
            color: darken($brand-primary, 10%);
        }
    }

    .navbar-collapse {
        border-color: fade(white, 2%);
    }

    .navbar-toggle {
        background-color: $brand-primary;
        border-color: $brand-primary;

        .icon-bar {
            background-color: white;
        }

        &:focus,
        &:hover {
            background-color: $brand-primary;
        }
    }

    .nav {
        li {
            a {
                @include heading-font;
                font-weight: 400;
                letter-spacing: 1px;
                color: white;

                &:focus,
                &:hover {
                    color: $brand-primary;
                    outline: none;
                }
            }
        }
    }
}

@media(min-width:768px) {
    .navbar-default {
        background-color: transparent;
        padding: 25px 0;
        transition: all 0.3s ease-in-out;
        border: none;
        box-shadow: none;

        .navbar-brand {
            font-size: 2em;
            transition: all 0.3s ease-in-out;
        }

        .navbar-nav>.active>a {
            border-radius: 3px;
            background: none;
            color: $brand-primary;
        }
    }

    .navbar-default.navbar-shrink {
        background-color: $gray-darkest;
        padding: 10px 0;

        .navbar-brand {
            font-size: 1.5em;
        }
    }
}

// Custom Navigation Bar
.navbar-custom {
    background-color: white;
    border-color: transparent;

    .navbar-brand {
        color: $theme-primary;
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: darken($theme-primary, 10%);
        }
        span {
            display: inline-block;
        }

        .brand-figure {
            margin-right: 7px;
            img {
                width: 16px;
                height: auto;
                vertical-align: top;
                transition: width 0.3s;
            }
        }
        .brand-text {
            img {
                width: 100px;
                height: auto;
                vertical-align: top;
                transition: width 0.3s;
            }
        }
    }
    .navbar-collapse {
        border-color: fade(white, 2%);
    }
    .navbar-toggle {
        background-color: white;
        border-color: $theme-primary;
        @include heading-font;
        color: $theme-primary;
        font-size: 12px;
        &:hover,
        &:focus {
            background-color: $theme-primary;
            color: white;
        }
    }
    .nav {
        li {
            a {
                @include heading-font;
                font-weight: 400;
                letter-spacing: 1px;
                color: $brand-primary;

                @media(min-width:768px) {
                    color: white;
                }

                &:focus,
                &:hover {
                    outline: none;
                    color: $brand-primary;

                    @media(min-width:768px) {
                        color: white;
                    }
                }
            }
        }
    }
}

@media(min-width:768px) {
    .navbar-custom {
        background-color: transparent;
        padding: 25px 0;
        transition: padding 0.3s;
        border: none;
        .navbar-brand {
            font-size: 2em;
            transition: all 0.3s;
        }
        .navbar-nav>.active>a {
            border-radius: 3px;
        }
        .nav {
            li {
                a {
                    &:hover,
                    &:focus {
                        color: $theme-primary;
                    }
                }
            }
        }
    }
}

// Navbar Change on Scroll
@media(min-width:768px) {
    .navbar-custom.affix {
        @include box-shadow;
        background-color: white;
        padding: 10px 0;
        .navbar-brand {
            font-size: 1.5em;
            padding: 0 15px;
            .brand-figure {
                img {
                    width: 50px;
                    height: auto;
                    vertical-align: top;
                    padding: 5px 5px 5px 0;
                }
                margin-right: 7px;
            }
            .brand-text {
                img {
                    width: 0;
                    height: auto;
                    vertical-align: top;
                }
            }
        }
        .nav {
            li {
                a {
                    color: $theme-primary;
                    &:hover,
                    &:focus {
                        color: $theme-primary;
                    }
                }
            }
        }
    }
}

header {
    @include clearfix;
    background-image: url('../img/hero-content-workshop.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    color: black;
    position: relative;

    .intro-text {
        padding-top: 100px;
        padding-bottom: 50px;

        @media only screen and (min-width: 768px) {
            padding-top: 250px;
            padding-bottom: 200px;
        }

        .intro-lead-in {
            font-style: normal;
            font-size: 1.5em;
            line-height: 1.3;
            margin-bottom: 25px;
            font-weight: 400;
            text-transform: none;

            @media only screen and (min-width: 768px) {
                font-style: normal;
                font-size: 2.5em;
                line-height: 1.3;
                margin-bottom: 25px;
            }

            &.small-intro-lead-in {
                font-size: 1.875em;

                @media only screen and (min-width: 960px) {
                    font-size: 2em;
                }
            }
        }

        .intro-heading {
            @include heading-font;
            font-weight: 600;
            font-size: 40px;
            line-height: 1.125;
            margin-bottom: 25px;

            @media only screen and (min-width: 768px) {
                @include heading-font;
                font-size: 75px;
                line-height: 75px;
                margin-bottom: 50px;
            }

            &.small-heading {
                font-size: 3em;
                line-height: 1.2;

                @media only screen and (min-width: 960px) {
                    font-size: 4em;
                }
            }
        }

        &.txt-white {
            color: #FFF;
        }
    }
}


header.hero-img {
    @media only screen and (min-width: 1280px) and (min-height: 1200px) {
        height: 1000px;
    }
}
header.newsletter-anmeldung {
    background-image: url('../img/header-gray.jpg');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    text-align: center;
    color: black;
    min-height: 500px;
    display: flex;
    align-items: center;

    &.footer-einzeilig {
        @media only screen and (min-width: 960px) {
            min-height: calc(100vh - 77px);
        }
    }

    &.footer-mehrzeilig {
        @media only screen and (min-width: 960px) {
            min-height: calc(100vh - 190px);
        }
    }

    .intro-text {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.index-success {
    header {
        height: calc(100vh - 77px);
    }
}

/*-----------------------------------------------------------------------------------
    Additional STYLE-CLASSES
-----------------------------------------------------------------------------------*/

.verticalAlignedMiddle {
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 350px;
        @media only screen and (min-width: 768px) { 
            height: 100vh;
        }
    }
}

.pattern-overlay {
    background: url('../img/pattern/pattern_50.png') repeat;
    width: 100%;
    height: 100%;
}

.background-overlay {
    //background: rgba(255,255,255,.5);
    //background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(205, 187, 136, 0.4));
    //background: linear-gradient(to bottom, rgba(0,0,0,.6) 0%,rgba(0,0,0,0.4) 100%);
    background: linear-gradient(rgba(0,0,0,.4) 0%,rgba(0,0,0,0.3) 100%);
    width: 100%;
    height: 100%;
}

// Global Section Styles
section {
    padding: 70px 0;
    @media only screen and (min-width: 768px) {
        padding: 100px 0;
    }
    h2.section-heading {
        font-size: 2.125em;
        margin-top: 0;
        margin-bottom: 15px;

        @media only screen and (min-width: 768px) {
            font-size: 2.5em;
        }
    }

    h3.section-subheading {
        font-size: 16px;
        @include serif-font;
        text-transform: none;
        font-style: italic;
        font-weight: 400;
        margin-bottom: 75px;
    }
}

// Footer
footer {
    border-top: 3px solid $gold;
    padding: 25px 0 10px;
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
    }

    span {
        display: inline-block;
    }
    .brand-figure {
        img {
            width: 13px;
            height: auto;
            vertical-align: top;
        }
        margin-right: 7px;
    }
    .brand-text {
        img {
            width: 90px;
            height: auto;
            vertical-align: top;
        }
    }

    span.copyright {
        line-height: 40px;
        @include heading-font;
        text-transform: none;
    }

    ul.quicklinks {
        text-align: center;
        margin-bottom: 0;
        margin-top: 20px;
        line-height: 1;
        @include heading-font;
        text-transform: none;
        vertical-align: bottom;
        font-size: 1.125em;

        @media (min-width: 768px) {
            text-align: left;
            margin-top: 0;
        }
        li {
            a {
                line-height: 2.5;
                padding: 10px;
                color: #111;
            }
        }
    }
}



.btn.active,
.btn:active,
.btn:active:focus,
.btn:focus {
    outline: none;
}



// Highlight Color Customization
::-moz-selection {
    text-shadow: none;
    background: $brand-primary;
}

::selection {
    text-shadow: none;
    background: $brand-primary;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $gray-lighter;
}


.formBox {
    background-color: rgb(255, 255, 255);
    @include box-shadow;
    box-sizing: border-box;
    padding-bottom: 10px;

    .formHeader {
        background: $brand-primary;
        color: white;
        padding: 5px 15px;
        position: relative;
        margin-bottom: 40px;
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $brand-primary;
            position: absolute;
            bottom: -20px;
        }
        h4 {
            text-transform: none;
            font-weight: 500;
            line-height: 1.3;
        }
    }
}

.no-padding {
    padding: 0 !important;
    margin: 0 !important;
}

.padding {
    padding-left: 15px;
    margin-top: 50px;
    @media (min-width: 992px) {
        padding-left: 70px;
        margin-top: 0;
    }
}

.listCheck {
    list-style-type: none;
    li {
        position: relative;
        margin-bottom: 7px;
        &:before {
            content: '';
            display: inline-block;
            height: 20px;
            width: 20px;
            border-left: 2px solid $brand-primary;
            border-bottom: 4px solid $brand-primary;
            border-top: 1px solid transparent;
            border-right: 1px solid transparent;
            transform: rotate(-45deg) scaleY(.5);
            position: absolute;
            left: -40px;
        }
    }
}

.listOrdered {
    margin: 0;
    padding: 0;
    list-style-type: none;
    >li {
        counter-increment: step-counter;
        margin-bottom: 10px;
        margin-left: 40px;
        &:before {
            content: counter(step-counter) " ";
            color: $brand-primary;
            font-weight: 600; //padding: 3px 8px;
            margin-left: -42px;
            margin-right: 10px;
            width: 33px;
            height: 33px;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            border: 1px solid $brand-primary;
        }
    }
}

#trurnit-eucookie {
    display: none;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .96);
    padding: 2% 5%;
    z-index: 200;
    font-size: 13px;
    font-weight: 400;
    box-shadow: 0 5px 21px 0 rgba(0, 0, 0, .5);

    @media only screen and(max-width: 530px) {
        padding: 10px;
    }
}

#trurnit-eucookie p {
    margin: 0 20px;
    display: inline-block;
    float: left;
    width: 60%;
    text-align: left;
    font-weight: 400;
    font-size: 1.125em;
    @media only screen and(max-width: 910px) {
        width: 70%;
    }
    @media only screen and(max-width:780px) {
        width: 90%;
    }
    @media only screen and(max-width:500px) {
        width: 65%;
        margin-right: 0;
    }
}

#trurnit-eucookie button {
    float: right;
    background: none;
    border: 1px solid $brand-primary;
    border-radius: 30px;
    outline: 0;
    color: $brand-primary;
    transition: all .3s ease-in-out;
    cursor: pointer;
    margin: 5px;
    padding: 7px 33px;
}

#trurnit-eucookie button:hover,
#trurnit-eucookie button:focus,
#trurnit-eucookie button:active {
    background: $brand-primary;
    color: white;
}

#trurnit-eucookie a,
#trurnit-eucookie a:visited {
    text-decoration: none;
    color: #333;
    transition: 200ms color;
    text-decoration: underline;
}

#trurnit-eucookie a:hover,
#trurnit-eucookie a:active {
    text-decoration: none;
}


.scroll-down {
    @include clearfix();
    position: absolute;
    bottom: -50px;
    transform: translateX(-50%);
    left: 50%;
}

.line {
  width: 1px;
  height: 100px;
  background-color: #ccc;
  display: block;
  
  &:before {
    content: '';
    width: 1px;
    height: 50%;
    top: 0;
    background-color: #000;
    display: block;
    animation: downWipe 2.2s cubic-bezier(0.76, 0, 0.3, 1) infinite;
  }
}

@keyframes downWipe {
  0% {
    height: 0;
  }
  
  50% {
    height: 100%;
  }
  
  70% {
    height: 100%;
    transform: scaleY(0.5);
    transform-origin: bottom;
  }
  100% {
    height: 100%;
    transform: scaleY(0);
    transform-origin: bottom;
  }
}